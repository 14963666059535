<template>
  <div>
    <div class="mb-8 flex justify-start max-w-3xl">
      <h1 class="font-bold text-3xl">
        <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('optfield.index')">Field Option Control</inertia-link>
        <span class="text-indigo-400 font-medium">/</span> {{ form.optfield.name }} 
      </h1>
    </div>
    <div class="bg-white rounded-md shadow overflow-hidden max-w-3xl">
      <form @submit.prevent="update">
          <div class="p-8 mb-2 align-top">
            <multiselect v-model="form.indicator_ids" 
                              track-by="id" 
                              label="short_version"
                              :maxHeight="150"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="indicators">
              </multiselect>                      
          </div>
          <ul class="pl-6 pt-0 w-full list-disc list-inside">
            <li class="pb-4" v-for="indicator in form.indicator_ids" :key="indicator.uuid">
              {{ indicator.short_version }}
            </li>
          </ul>
        <p>&nbsp;</p>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import LoadingButton from '@/Shared/LoadingButton'
import Multiselect from 'vue-multiselect'

export default {
  metaInfo() {
    return {
      title: `${this.form.optfield.name}`,
    }
  },
  components: {
    Link,
    Icon,
    LoadingButton,
    Multiselect
  },
  layout: Layout,
  props: {
    optfield: Object,
    indicator_ids: Array,
    indicators: Array,

  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        _method: 'put',
        optfield: this.optfield,
        indicator_ids: this.indicator_ids,
      }),
    }
  },
  methods: {
    update() {
      this.form.post(this.route('optfield.update', this.optfield.id), {
        onSuccess: () => this.form.reset('password', 'photo'),
      })
    },
  },
}
</script>
