<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">Results</h1>
    <div class="mb-6 flex justify-between items-center">
		<div class="flex justify-content-end">
			<div class="dropdown inline-block relative mr-2">
				<button class="bg-gray-300 text-gray-700 font-semibold py-3 px-4 rounded inline-flex items-center">
					<span class="mr-1">Download Report</span>
					<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
				</button>
				<ul class="dropdown-menu absolute hidden text-gray-700 pt-1">
					<li class=""><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" :href="route('results.download', this.filters)">Selected Fields</a></li>
					<li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" :href="route('results.downloadAll')">All Fields</a></li>
				</ul>
			</div>
			<inertia-link v-if="$page.props.auth.user.perm.records.edit" class="btn-indigo" :href="route('results.create')">
				<span>Create</span>
				<span class="hidden md:inline">Result</span>
			</inertia-link>
      </div>
       <search-filter v-if="$page.props.auth.user.perm.records.edit" v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
      <search v-else v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
      </search>
      
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto" style="min-height:300px;">
      <div>
        <div class="text-left font-bold grid grid-cols-12 gap-2 text-xs">
          <div class="px-3 pt-6 pb-2 cursor-pointer" :class="$page.props.auth.user.perm.records.edit ? ' col-span-2 ' : ' col-span-4 '" @click="sort('title')">Title<sort-icon fieldName="title" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div class="px-2 pt-6 pb-2 cursor-pointer" @click="sort('status')">Status<sort-icon fieldName="status" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div class="px-2 pt-6 pb-2 cursor-pointer" @click="sort('indicator')">Indicator<sort-icon fieldName="indicator" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div class="px-2 pt-6 pb-2 cursor-pointer" @click="sort('resultarea')">Result Area(s)<sort-icon fieldName="resultarea" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div class="px-2 pt-6 pb-2  cursor-pointer" @click="sort('country')">Country(s)<sort-icon fieldName="country" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div class="px-2 pt-6 pb-2 cursor-pointer" @click="sort('year_result_met')">Year Result Met<sort-icon fieldName="year_result_met" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div class="px-2 pt-6 pb-2 cursor-pointer" @click="sort('activity')">Activity Code(s)<sort-icon fieldName="activity" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div class="px-2 pt-6 pb-2  cursor-pointer" @click="sort('activitylead')">Activity Lead(s)<sort-icon fieldName="activitylead" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div v-if="$page.props.auth.user.perm.records.edit" class="px-2 pt-6 pb-2 col-span-2 cursor-pointer" @click="sort('recent_note')">Recent Note<sort-icon fieldName="recent_note" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>
          <div v-if="$page.props.auth.user.perm.records.edit" class="px-2 pt-6 pb-2  cursor-pointer" @click="sort('note_date')">Note Date<sort-icon fieldName="note_date" :sortBy="form.sort_by" :sortDirection="form.sort_direction"></sort-icon></div>          
        </div>
        <div class="text-left font-normal grid grid-cols-12 gap-2 text-xs">
          <text-input class="px-3 pb-4 hidden lg:block" :class="$page.props.auth.user.perm.records.edit ? ' col-span-2 ' : ' col-span-4 '"  v-model="form.title_filter" />
          <!-- <text-input class="px-2 pb-4 hidden lg:block" v-model="form.status_filter" /> -->
          <multiselect class="px-2 hidden lg:block font-normal text-sm" v-model="form.status_filter" 
                            :multiple="true" 
                            :close-on-select="true" 
                            placeholder=""
                            :searchable="false"
                            selectedLabel=""
                            selectLabel=""
                            deselectLabel="Deselect"
                            :options="status_filters">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length">{{ values.length }} filters</span></template>
          </multiselect>
          <multiselect class="px-2 hidden lg:block font-normal text-sm" v-model="form.indicator_filter" 
                            :multiple="true" 
                            :close-on-select="true" 
                            :max-height="200"
                            placeholder=""
                            :searchable="false"
                            selectedLabel=""
                            selectLabel=""
                            deselectLabel="Deselect"
                            :options="indicator_filters">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length">{{ values.length }} filters</span></template>
          </multiselect>
          <multiselect class="px-2 hidden lg:block font-normal text-sm" v-model="form.resultarea_filter" 
                            :multiple="true" 
                            :close-on-select="true" 
                            placeholder=""
                            :searchable="false"
                            selectedLabel=""
                            selectLabel=""
                            deselectLabel="Deselect"
                            :options="resultarea_filters">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length">{{ values.length }} filters</span></template>
          </multiselect>
          <text-input class="px-2 pb-4 hidden lg:block" v-model="form.country_filter" />
          <multiselect class="px-2 hidden lg:block font-normal text-sm" v-model="form.year_result_met_filter" 
                            :multiple="true" 
                            :close-on-select="true" 
                            placeholder=""
                            :searchable="false"
                            selectedLabel=""
                            selectLabel=""
                            deselectLabel="Deselect"
                            :options="yearresultmet_filters">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length">{{ values.length }} filters</span></template>
          </multiselect>
          <multiselect class="px-2 hidden lg:block font-normal text-sm" v-model="form.activity_code_filter" 
                            :multiple="true" 
                            :max-height="200"
                            :close-on-select="true" 
                            placeholder=""
                            :searchable="true"
                            selectedLabel=""
                            selectLabel=""
                            deselectLabel="Deselect"
                            :options="activitycode_filters">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length">{{ values.length }} filters</span></template>
          </multiselect>
          <text-input class="px-2 pb-4 hidden lg:block" v-model="form.activity_lead_filter" />
          <text-input v-if="$page.props.auth.user.perm.records.edit" class="px-2 pb-4 col-span-2 hidden lg:block" v-model="form.recent_note_filter" />
          <text-input v-if="$page.props.auth.user.perm.records.edit" class="px-2 pb-4 hidden lg:block" v-model="form.note_date_filter" />
        </div>
        <div v-for="result in results.data" :key="result.id">          
          <result-row :result="result" :urlResult="urlResult(result, $page.props.auth.user)"></result-row>
        </div>
        <div v-if="results.data.length === 0">
          <div class="border-t px-4 py-4 item-center" colspan="4">No results found.</div>
        </div>
      </div>
    </div>
    <div class="justify-between lg:flex" v-if="results.total > 0">
      <div class="mt-6 mr-5 lg:shrink-0 ">
        <div class="mb-1 px-4 py-2 text-sm leading-4 bg-white border rounded"> Showing {{ results.from + ' to ' + results.to + ' of ' + results.total }} entries </div>
        <div>
          <select-input class="text-sm lg:shadow-inner rounded-lg" v-model="form.page_len" label="">
              <option :value="10">10 rows per page</option>
              <option :value="20">20 rows per page</option>
              <option :value="50">50 rows per page</option>
              <option :value="100">100 rows per page</option>
          </select-input>
        </div>
      </div>
      <pagination class="mt-6" :links="results.links" />
    </div>
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import Search from '@/Shared/Search'
import SearchFilter from '@/Shared/SearchFilter'
import SelectInput from '@/Shared/SelectInput'
import TextInput from '@/Shared/TextInput'
import SortIcon from '@/Shared/SortIcon'
import Multiselect from 'vue-multiselect'
import ResultRow from '@/Shared/ResultRow'

export default {
  metaInfo: { title: 'Results' },
  components: {
    Icon,
    Pagination,
    Search,
    SearchFilter,
    SelectInput,
    SortIcon,
    Multiselect,
    TextInput,
    ResultRow
  },
  layout: Layout,
  props: {
    filters: Object,
    page_len: Number,
    results: Object,
    isMisAdmin: Boolean,
    status_filters: Array,
    indicator_filters: Array,
    resultarea_filters: Array,
    yearresultmet_filters: Array,
    activitycode_filters: Array,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
        title_filter: this.filters.title_filter,
        status_filter: this.filters.status_filter,
        indicator_filter: this.filters.indicator_filter,
        resultarea_filter: this.filters.resultarea_filter,
        country_filter: this.filters.country_filter,
        year_result_met_filter: this.filters.year_result_met_filter,
        activity_code_filter: this.filters.activity_code_filter,
        activity_lead_filter: this.filters.activity_lead_filter,
        recent_note_filter: this.filters.recent_note_filter,
        note_date_filter: this.filters.note_date_filter,
        year_requested: this.filters.year_requested,
        media_id: this.filters.media_id,
        sort_by: this.filters.sort_by,
        sort_direction: this.filters.sort_direction,
        page_len: this.page_len,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(this.route('results.index'), pickBy(this.form), { preserveState: true })
      }, 800),
    },
  },
  methods: {
    urlResult(result, user) {
      if(user.perm.records.edit) {
        return route('results.edit', result.id)
      } else if(user.perm.records.non_usaid_view) {
        return route('results.staff', result.id);
      } else {
        return route('results.show', result.id);
      }
    },
    reset() {
      // Preserve certain form values
      const page_len_temp = this.form.page_len

      // Clear form values
      this.form = mapValues(this.form, () => null)

      // Reset sorting
      this.form.sort_by = 'title'
      this.form.sort_direction = 'ASC'

      // Restore preserved form values
      this.form.page_len = page_len_temp
    },
    sort(column) {
      this.form.sort_by = column
      this.form.sort_direction = this.form.sort_direction == 'ASC' ? 'DESC' : 'ASC'
    },
    stringLimiter(text) {
      if(typeof text === "string") {
        return text.length > 120 ? text.slice(0, 120) + '...' : text;
      } else {
        return text;
      }
    },
    stringConcater(text, idx) {      
      return idx > 0 ? '; ' + text.trim() : text.trim();      
    }
  }
}
</script>

<style scoped>
.dropdown:hover .dropdown-menu {
  	display: block;
}

</style>
