<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">Result Areas</h1>
    <div class="mb-6 flex justify-between items-center">
      <search-filter v-if="$page.props.auth.user.perm.records.edit" v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
        <label class="block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>
      <search v-else v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">
      </search>
      <inertia-link v-if="$page.props.auth.user.perm.records.edit" class="btn-indigo" :href="route('resultareas.create')">
        <span>Create</span>
        <span class="hidden md:inline">Result Area</span>
      </inertia-link>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4 cursor-pointer" @click="sort('short_version')">Title</th>
        </tr>
        <tr v-for="resultarea in resultareas.data" :key="resultarea.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <inertia-link v-if="$page.props.auth.user.perm.records.edit" class="px-6 py-4 flex items-center" :href="route('resultareas.edit', resultarea.id)" tabindex="-1">
              <div v-if="resultarea.short_version">
                {{ resultarea.short_version }}
              </div>
            </inertia-link>
            <inertia-link v-else class="px-6 py-4 flex items-center" :href="route('resultareas.show', resultarea.id)" tabindex="-1">
              <div v-if="resultarea.short_version">
                {{ resultarea.short_version }}
              </div>
            </inertia-link>
          </td>
          <td class="border-t w-px">
            <inertia-link v-if="$page.props.auth.user.perm.records.edit" class="px-4 flex items-center" :href="route('resultareas.edit', resultarea.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </inertia-link>
            <inertia-link v-else class="px-4 flex items-center" :href="route('resultareas.show', resultarea.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </inertia-link>
          </td>
        </tr>
        <tr v-if="resultareas.data.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No resuls found.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="resultareas.links" />
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import Search from '@/Shared/Search'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  metaInfo: { title: 'Result Areas' },
  components: {
    Icon,
    Pagination,
    Search,
    SearchFilter,
  },
  layout: Layout,
  props: {
    filters: Object,
    resultareas: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
        sort_by: 'short_version',
        sort_direction: 'ASC',        
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(this.route('resultareas'), pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)

      // Reset sorting
      this.form.sort_by = 'short_version'
      this.form.sort_direction = 'ASC'
    },
    sort(column) {
      this.form.sort_by = column
      this.form.sort_direction = this.form.sort_direction == 'ASC' ? 'DESC' : 'ASC'
    }
  },
}
</script>
