<template>
    <div>        
        <div v-for="(section, index) in sections" v-bind:key="index" class="lg:w-3/4">
            <div class="mb-4 flex flex-wrap">
                <multiselect v-model="section.media_id" 
                            track-by="id" 
                            class="basis-full lg:flex-1 lg:mr-6"
                            label="short_version"                            
                            :select="disabledOption()"
                            :multiple="false" 
                            :close-on-select="true" 
                            :options="options">
                </multiselect>
                <div class="font-medium flex-grow-0 mr-3 text-right pt-3 mt-4 lg:mt-0">Qty:</div>
                <text-input type="number" 
                            min="0"
                            v-model.number="section.qty" 
                            class="font-medium flex-1 mr-3 mt-4 lg:mt-0" 
                            />
                <div class="flex-grow-0  mt-4 lg:mt-0">
                    <button type="button" class="btn-red ml-auto text-sm" v-on:click="removeSection(index)">
                        <icon name="close" class="w-4 h-4 fill-white" />
                    </button>
                </div>
            </div>
        </div>
        <button v-show="isShown" class="btn-indigo ml-auto rounded-full text-sm" type="button" v-on:click="addComponent()">Add New Row</button>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Icon from '@/Shared/Icon'
import TextInput from '@/Shared/TextInput'

export default {
    props: ['sections', 'options'],
    components: {
        Icon,
        TextInput,
        Multiselect,
    },
    data() {
        return {
            isShown: true
        }
    },
    computed() {
        
    },
    methods: {
        removeSection: function(index) {
            this.sections.splice(index, 1)
            this.isShown = true
        },
        addComponent: function() {
            if(this.sections.length < this.options.length) {
                this.sections.push({
                    media_id: null,
                    qty: null,
                });
            }
            if(this.sections.length >= this.options.length) {
                this.isShown = false
            }
        },
        disabledOption() {
            this.options.forEach((optElement, optIdx) => { 
                optElement.$isDisabled = false;
            });
            this.options.forEach((optElement, optIdx) => { 
                this.sections.forEach((element, idx) => { 
                    if(element.media_id != null) {
                        if(optElement.id == element.media_id.id) {
                            optElement.$isDisabled = true;
                        } 
                    }                    
                })
            })
        },
        selected: function(index) {
            if(this.sections[index].hasOwnProperty('media_id')) {
                if(this.sections[index].media_id !== null) {
                    this.sections.forEach((element, idx) => {
                        if(index != idx && index > idx) {
                            if(element.media_id != null) {
                                if(element.media_id.id == this.sections[index].media_id.id) {
                                    alert('You have choosen this media, please choose the other one');
                                    this.sections.splice(index,1);
                                    this.sections.push({
                                        media_id: null,
                                        qty: null,
                                    });
                                    return;
                                }
                            }                            
                        }
                    });
                }   
            }
        },
    }
};
</script>