<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('results.index')">Results</inertia-link>
      <span class="text-indigo-400 font-medium">/</span> Create
    </h1>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store" autocomplete="off">
        <div class="p-8">
            <form-row label="Title" :isRequired=true><text-input-required v-model="form.title" :error="form.errors.title"/></form-row>
            <form-row label="Narrative"><textarea-input v-model="form.narrative" :error="form.errors.narrative" /></form-row>
            <form-row label="Status">
              <multiselect  v-model="form.marking" 
                            track-by="id" 
                            label="tracking"
                            :multiple="false" 
                            :close-on-select="true" 
                            :options="transitions">
              </multiselect>
            </form-row>
            <form-row label="Indicator">
              <multiselect  v-model="form.indicator_id" 
                            track-by="id" 
                            label="short_version"
                            :multiple="false" 
                            :close-on-select="true" 
                            :options="indicators">
              </multiselect>
            </form-row>
            
            <div class="mb-8 pt-6 pl-4 pr-4 pb-1 shadow-lg bg-gray-50" v-show="boolA1 || boolA2 || boolA3 || boolA4 || boolA5 || boolA6 || boolA7 || boolA8 || boolB1 || boolB2 || boolB4 || boolB6 || boolC1 || boolC2 || boolT1">
              <form-row label="No. of Females Trained" v-show="boolT1">
                <text-input type="number" 
                          min="0"
                          v-model.number="form.no_of_females_trained" 
                          :error="form.errors.no_of_females_trained" 
                          class="w-full lg:w-1/4"
                          @input="updateTotalTrained" />
              </form-row>

              <form-row label="No. of Males Trained" v-show="boolT1">
                <text-input type="number"                           
                          min="0"
                          v-model.number="form.no_of_males_trained" 
                          :error="form.errors.no_of_males_trained" 
                          class="w-full lg:w-1/4"
                          @input="updateTotalTrained" />
              </form-row>

              <form-row label="No. of Unknown Trained" v-show="boolT1">
                <text-input type="number"                           
                          min="0"
                          v-model.number="form.no_of_unknown_gender_trained" 
                          class="w-full lg:w-1/4"
                          :error="form.errors.no_of_unknown_gender_trained" 
                          @input="updateTotalTrained" />
              </form-row>
              <form-row label="Total No. Trained" v-show="boolT1">
                <span class="text-green-500 font-bold">{{ form.total_no_trained }}</span>
                <input type="hidden" v-model.number="form.total_no_trained" />
              </form-row>
              
              <form-row label="Type of TB Data Hub resource or information product" v-show="boolA1 || boolB1">
                <multiselect  v-model="form.hubresource_id" 
                              track-by="id" 
                              label="short_version"
                              :multiple="false" 
                              :close-on-select="true" 
                              :options="datahub_resources">
                </multiselect>
              </form-row>

              <form-row label="Intended geographic use" v-show="boolA1 || boolA6 || boolB1">
                <multiselect  v-model="form.geographic" 
                              :multiple="false" 
                              :close-on-select="true" 
                              :options="geographics">
                </multiselect>
              </form-row>
              <form-row label="Type of Assessment" v-show="boolA2 || boolB2">
                <multiselect  v-model="form.typeassessment_id" 
                              track-by="id" 
                              label="short_version"
                              :multiple="false" 
                              :close-on-select="true" 
                              :options="assessments">
                </multiselect>
              </form-row>
              <form-row label="Does work plan include training" v-show="boolA3">
                <ToggleInput :toggle="form.include_training"></ToggleInput>
              </form-row>
              <form-row label="Type of stakeholder" v-show="boolA4 || boolB2 || boolB4 || boolB6">
                <multiselect  v-model="form.typestakeholder_ids" 
                              track-by="id" 
                              label="short_version"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="typestakeholders">
                </multiselect>
              </form-row>
              <form-row label="Aspect of PBMEF Reporting" v-show="boolA5">
                <multiselect  v-model="form.pbmef_ids" 
                              track-by="id" 
                              label="short_version"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="pbmefs">
                </multiselect>              
              </form-row>

              <form-row label="Type Of Electronic Media Recipient" v-show="boolA8">
                <DataGridMediaField :sections="form.media_ids" :options="medias"></DataGridMediaField>
              </form-row>

              <form-row label="Year Requested" v-show="boolA5 || boolA8">
                <multiselect  v-model="form.year_requested" 
                              :multiple="false" 
                              :close-on-select="true" 
                              :options="year_requesteds">
                </multiselect>
              </form-row>

              <form-row label="Product type" v-show="boolA6 || boolB6">
                <multiselect  v-model="form.producttype_ids" 
                              track-by="id" 
                              label="short_version"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="producttypes">
                </multiselect>
              </form-row>

              <form-row label="Type of skill supported" v-show="boolA1 ||boolA6 || boolB2 || boolB4 || boolB6">
                <multiselect  v-model="form.skillsupported_ids" 
                              track-by="id" 
                              label="short_version"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="skillsupporteds">
                </multiselect>
              </form-row>

              <form-row label="Product level" v-show="boolA1 ||boolA2 ||boolA6 || boolB1 || boolC1">
                <multiselect  v-model="form.product_level" 
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="product_levels">
                </multiselect>
              </form-row>

              <form-row label="Type of dissemination event" v-show="boolA7">
                <multiselect  v-model="form.dissemination_ids" 
                              track-by="id" 
                              label="short_version"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="disseminations">
                </multiselect>
              </form-row>

              <form-row label="TB Roadmap Technical Area" v-show="boolB1">
                <multiselect  v-model="form.roadmap_ids" 
                              track-by="id" 
                              label="short_version"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="roadmaps">
                </multiselect>
              </form-row>

              <form-row label="Type of decision-maker" v-show="boolC1">
                <multiselect  v-model="form.decisionmaker_ids" 
                              track-by="id" 
                              label="short_version"
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="decisionmakers">
                </multiselect>
              </form-row>

              <form-row label="Type of decision made" v-show="boolC1">
                <multiselect  v-model="form.decision_made" 
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="decision_mades">
                </multiselect>
              </form-row>

              <form-row label="Type of system improvement" v-show="boolC2">
                <multiselect  v-model="form.system_improvement" 
                              :multiple="true" 
                              :close-on-select="true" 
                              :options="system_improvements">
                </multiselect>
              </form-row>

            </div>

            <form-row label="Result Areas">
              <multiselect  v-model="form.resultarea_ids" 
                            track-by="id" 
                            label="short_version"
                            :multiple="true" 
                            :close-on-select="true" 
                            :options="resultareas">
              </multiselect>
            </form-row>
            <form-row label="Country(ies)">
              <multiselect  v-model="form.country_ids" 
                                track-by="country_id" 
                                label="long_name"
                                :multiple="true" 
                                :close-on-select="true" 
                                :options="countries">
                  </multiselect>
            </form-row>

            <form-row label="Primary MEL Contact:">
              <multiselect  v-model="form.pmcontact_id" 
                            track-by="contact_id" 
                            label="full_name"
                            :multiple="false" 
                            :close-on-select="true" 
                            :options="pmcontacts">
              </multiselect>
            </form-row>

            <!-- <form-row label="Gender">
              <ToggleInput :toggle="form.gender"></ToggleInput>
            </form-row> -->
            
            <form-row label="Year Result Met">
              <multiselect  v-model="form.year_result_met" 
                                :multiple="true" 
                                :close-on-select="true" 
                                :options="years">
                  </multiselect>
            </form-row>

            <form-row label="Year Result Reported">
              <multiselect  v-model="form.year_result_reported" 
                                :multiple="true" 
                                :close-on-select="true" 
                                :options="years">
                  </multiselect>
            </form-row>
            
            <form-row label="Files">
              <DataGridFileField :sections="form.new_uploads" :file_set_title="file_set_title" :add_button_label="add_button_label"></DataGridFileField>
            </form-row>
            
            <form-row label="Notes">
              <DataGridNoteFileField 
                    :sections="form.notes" 
                    :note_types="note_types"
                    :deleted_notes="form.deleted_notes">
              </DataGridNoteFileField>
            </form-row>

            <form-row label="Associated Activities">
              <multiselect  v-model="form.activity_ids" 
                                track-by="activity_id" 
                                label="activity_code"
                                :multiple="true" 
                                :close-on-select="true" 
                                :options="activities"
                                @input="updateActivityRelatedFields">
              </multiselect>
            </form-row>
            <form-row v-if="form.activity_ids.length > 0">
              <ul class="w-full list-disc list-inside grid grid-cols-4 gap-4">
                <li class="pb-2" v-for="activity in form.activity_ids" :key="activity.activity_id">
                  <a class="text-indigo-400" :href="`${ activity_url_prefix }${ activity.activity_code }`" target='_blank'>{{ activity.activity_code }}</a>
                </li>
              </ul>
            </form-row>
            
            <form-row label="Contributing Sub-Activities">
              <multiselect  v-model="form.subactivity_ids" 
                            track-by="uuid" 
                            label="title"
                            :multiple="true" 
                            :close-on-select="true" 
                            :options="subactivities">
              </multiselect>
            </form-row>
            <form-row v-if="form.subactivity_ids.length > 0">
              <ul class="w-full list-disc list-inside">
                <li class="pb-4" v-for="subactivity in form.subactivity_ids" :key="subactivity.uuid">
                  <a class="text-indigo-400" :href="`${ subactivity_url_prefix }${ subactivity.uuid }`" target='_blank'>{{ subactivity.title }}</a>
                </li>
              </ul>
            </form-row>
            
            <form-row label="Contributing Deliverables">
              <multiselect  v-model="form.deliverable_ids" 
                            track-by="uuid" 
                            label="title"
                            :multiple="true" 
                            :close-on-select="true" 
                            :options="deliverables">
              </multiselect>
            </form-row>
            <form-row v-if="form.deliverable_ids.length > 0">
              <ul class="w-full list-disc list-inside">
                <li class="pb-4" v-for="deliverable in form.deliverable_ids" :key="deliverable.uuid">
                  <a class="text-indigo-400" :href="`${ deliverable_url_prefix }${ deliverable.uuid }`" target='_blank'>{{ deliverable.title }}</a>
                </li>
              </ul>
            </form-row>

          <form-row label="Contributing Stakeholders">
            <multiselect  v-model="form.activitystakeholder_ids" 
                            track-by="uuid" 
                            label="long_name"
                            :multiple="true" 
                            :close-on-select="true" 
                            :options="activitystakeholders">
              </multiselect>
          </form-row>
          <form-row v-if="form.activitystakeholder_ids.length > 0">
            <ul class="w-full list-disc list-inside">
              <li class="pb-4" v-for="activitystakeholder in form.activitystakeholder_ids" :key="activitystakeholder.uuid">
                <!-- <a class="text-indigo-400" :href="`${ activitystakeholder_url_prefix }${ activitystakeholder.uuid }`" target='_blank'>{{ activitystakeholder.long_name }}</a> -->
                {{ activitystakeholder.long_name }}
              </li>
            </ul>
          </form-row>
        </div>
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex justify-end items-center">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Create Result</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextInputRequired from '@/Shared/TextInputRequired'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'

import DataGridFileField from '@/Shared/DataGridFileField'
import DataGridNoteFileField from '@/Shared/DataGridNoteFileField'
import DataGridMediaField from '@/Shared/DataGridMediaField'
import ToggleInput from '@/Shared/ToggleInput'
import FormRow from '@/Shared/FormRow'

import Multiselect from 'vue-multiselect'

import axios from 'axios'

export default {
  metaInfo: { title: 'Create Result' },
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
    TextInputRequired,
    TextareaInput,
    DataGridFileField,
    DataGridNoteFileField,
    DataGridMediaField,

    Multiselect,
    FormRow,
    ToggleInput,
  },
  layout: Layout,
  computed: {
    boolA1: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('60886d68-d855-400a-85b0-0a4404920c72') } else { return false } }, // A1
    boolA2: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('63274f2e-7612-4383-a05c-f7468b7321e2') } else { return false } }, // A2
    boolA3: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('e3c3be82-857a-4b53-9bda-23086765a491') } else { return false } }, // A3
    boolA4: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('8e98e53b-5d87-4358-a3b9-c75bc38c1b32') } else { return false } }, // A4
    boolA5: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('2fc99d38-a5b6-4b21-83c4-0821f0b635b1') } else { return false } }, // A5
    boolA6: function () { /* return true; */ if (this.form.indicator_id) { 
      if(this.form.indicator_id.id.startsWith('5daad65b-0e2b-4b3f-90d9-77c165cb5aa8') || // A6
        this.form.indicator_id.id.startsWith('39d31ba7-8363-4129-99d4-9e62be666d11') || // A7
        this.form.indicator_id.id.startsWith('7850e492-ec6e-4071-af89-aac8e090cf5e')) {  // A8
          return true 
      }
    } else { return false } }, // A6
    boolA7: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('0bd0d3fb-34ef-4c45-a7f4-73aa4f567e82') } else { return false } }, // A7 -> A9
    boolA8: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('07af3b6b-6435-4391-9474-ad5d40a2f062') } else { return false } }, // A8 ->A10
    boolB1: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('aee54731-ef98-468e-a001-9e559eed8bdd') } else { return false } }, // B1
    boolB2: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('da3c9bef-be4e-48dc-8212-6e4e8def69bf') } else { return false } }, // B2
    boolB4: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('243b4fa7-6cc6-4f40-8317-8710054c7c8d') } else { return false } }, // B4
    boolB6: function () { /* return true; */ if (this.form.indicator_id) { 
      if(this.form.indicator_id.id.startsWith('e2fce0a9-b51f-4297-8481-3238278cf55f') || // B6
          this.form.indicator_id.id.startsWith('31d227b2-80ac-497a-b1d9-79bf67f0d7b5') || // B7
          this.form.indicator_id.id.startsWith('eff7fc75-3007-4e2e-bb74-5b5193bd0ebd')) { // B8
        return true
      }
    } else { return false } }, // B6
    boolC1: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('548cbb70-7436-438e-9aa0-abfd6deeb949') } else { return false } }, // C1
    boolC2: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('3ca93ac8-d43c-4c5f-817e-8185f4d5d7d7') } else { return false } }, // C2
    boolT1: function () { /* return true; */ if (this.form.indicator_id) { return this.form.indicator_id.id.startsWith('dee79026-3d9e-4384-9b39-eb6a9b24fc25') } else { return false } }, // T1 -> A12
  },
  props: {    
    transitions: Array,
    indicators: Array,
    resultareas: Array,
    countries: Array,
    years: Array,
    note_types: Array,

    datahub_resources: Array,
    geographics: Array,
    assessments: Array,
    typestakeholders: Array,
    pbmefs: Array,
    year_requesteds: Array,
    producttypes: Array,
    skillsupporteds: Array,
    product_levels: Array,
    disseminations: Array,
    roadmaps: Array,
    decisionmakers: Array,
    decision_mades: Array,
    system_improvements: Array,

    activities: Array,
    pmcontacts: Array,
    subactivities: Array,
    deliverables: Array,
    medias: Array,
    activitystakeholders: Array,
    url: String,
    activity_url_prefix: String,
    subactivity_url_prefix: String,
    deliverable_url_prefix: String,
    activitystakeholder_url_prefix: String,
    needsuse_url_prefix: String,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        title: null,
        narrative: null,
        transition: null,
        marking: null,
        indicator_id: null,
        resultarea_ids: null,
        country_ids: null,
        pmcontact_id: null,
        gender: {
          label: 'Gender',
          value: false
        },
        no_of_females_trained: 0,
        no_of_males_trained: 0,
        no_of_unknown_gender_trained: 0,
        total_no_trained: 0,
        year_result_met: null,
        year_result_reported: null,
        new_uploads: [{
          value: ''
        }],

        hubresource_id: null,
        geographic: null,
        typeassessment_id: null,
        include_training: {
          label: 'Does work plan include training',
          value: false
        },
        typestakeholder_ids: null,
        pbmef_ids: null,
        year_requested: null,
        producttype_ids: null,
        skillsupported_ids: null,
        product_level: null,
        media_ids: [],
        dissemination_ids: null,
        roadmap_ids: null,
        decisionmaker_ids: null,
        decision_made: null,
        system_improvement: null,

        subactivity_ids: [],
        deliverable_ids: [],
        activitystakeholder_ids: [],
        needsuse_ids: null,
        notes: [],
        deleted_notes: [],
        activity_ids: [],
      }),
      file_set_title: '',
      add_button_label: "Add New File",
    }
  },
  methods: {
    store() {
      this.form.post(this.route('results.store'))
    },
    updateTotalTrained: function() {
        this.form.total_no_trained = parseInt(this.form.no_of_females_trained) + parseInt(this.form.no_of_males_trained) + parseInt(this.form.no_of_unknown_gender_trained)
    },
    updateActivityRelatedFields() {
      let params = { params: JSON.stringify( this.form.activity_ids.map( a => a.activity_id ) ) };

      axios.get(this.url + '/getactivityrelatedvalues/', params)
        .then(response => {
          this.subactivities = response.data.subactivity_ids;
          this.deliverables = response.data.deliverable_ids;
          this.activitystakeholders = response.data.activitystakeholder_ids;
        })
        .catch(errors => {
          // console.log(errors);
        });
    },
  },
}
</script>
