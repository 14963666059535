<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('dashboardtitle')">Dashboard Title</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      Dashboard Title
    </h1>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="p-8">
          <form-row label="Title Chart Indicator">
            <text-input v-model="form.TITLEINDICATOR" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <!-- <form-row label="Label Bottom Chart Indicator">
            <text-input v-model="form.BOTTOMINDICATOR" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row> -->
          <form-row label="Title Chart Trained People">
            <text-input v-model="form.TITLETRAINEDPEO" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <!-- <form-row label="Label Bottom Chart Trained People">
            <text-input v-model="form.BOTTOMTRAINEDPE" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row> -->
          <form-row label="Title Chart Output">
            <text-input v-model="form.TITLEOUTPUT" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <form-row label="Label Left Side Chart Output">
            <text-input v-model="form.SIDEOUTPUT" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <form-row label="Label Bottom Side Chart Output">
            <text-input v-model="form.BOTTOMOUTPUT" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <form-row label="Title Chart Outcome">
            <text-input v-model="form.TITLEOUTCOME" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <form-row label="Label Left Side Chart Outcome">
            <text-input v-model="form.SIDEOUTCOME" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <form-row label="Label Bottom Side Chart Outcome">
            <text-input v-model="form.BOTTOMOUTCOME" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <form-row label="Title Chart Media">
            <text-input v-model="form.TITLEMEDIA" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <form-row label="Label Left Side Chart Media">
            <text-input v-model="form.SIDEMEDIA" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
          <form-row label="Label Bottom Side Chart Media">
            <text-input v-model="form.BOTTOMMEDIA" class="pr-6 w-full lg:w-3/4 font-medium" />
          </form-row>
        </div>
        
        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import FormRow from '@/Shared/FormRow'

export default {
  metaInfo() {
    return {
    }
  },
  components: {
    LoadingButton,
    TextInput,
    TrashedMessage,
    FormRow,
  },
  layout: Layout,
  props: {
    TITLEINDICATOR: String, 
    TITLETRAINEDPEO: String, 
    TITLEOUTPUT: String, 
    TITLEOUTCOME: String, 
    TITLEMEDIA: String,
    SIDEOUTPUT: String, 
    SIDEOUTCOME: String, 
    SIDEMEDIA: String,
    BOTTOMINDICATOR: String,
    BOTTOMTRAINEDPEO: String,
    BOTTOMOUTPUT: String,
    BOTTOMOUTCOME: String,
    BOTTOMMEDIA: String,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        TITLEINDICATOR: this.TITLEINDICATOR,
        TITLETRAINEDPEO: this.TITLETRAINEDPEO,
        TITLEOUTPUT: this.TITLEOUTPUT,
        TITLEOUTCOME: this.TITLEOUTCOME,
        TITLEMEDIA: this.TITLEMEDIA,
        SIDEOUTPUT: this.SIDEOUTPUT,
        SIDEOUTCOME: this.SIDEOUTCOME,
        SIDEMEDIA: this.SIDEMEDIA,
        BOTTOMINDICATOR : this.BOTTOMINDICATOR,
        BOTTOMTRAINEDPEO : this.BOTTOMTRAINEDPEO,
        BOTTOMOUTPUT : this.BOTTOMOUTPUT,
        BOTTOMOUTCOME : this.BOTTOMOUTCOME,
        BOTTOMMEDIA : this.BOTTOMMEDIA,
      }),
    }
  },
  methods: {
    update() {
      this.form.put(this.route('dashboardtitle.update'))
    },
  },
}
</script>
