<template>
	<div class="p-6 bg-indigo-800 min-h-screen flex justify-center items-center">
		<div class="w-full max-w-md text-lg">
		<!-- logo class="block mx-auto w-full max-w-xs fill-white md:w-56" height="50" / -->
		<!-- span class="text-white">Debug mode: {{ this.$page.props.appDebug }}</span -->
		<form v-if="isDebug" class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="login">
			<div class="px-10 py-12">
				<h1 class="text-center font-bold text-3xl">TB DIAH Results System</h1>
				<div class="mx-auto mt-6 w-24 border-b-2" />
				<text-input v-model="form.email" :error="form.errors.email" class="mt-10" label="Email" type="email" autofocus autocapitalize="off" />
				<text-input v-model="form.password" :error="form.errors.password" class="mt-6" label="Password" type="password" />
				<label class="mt-6 select-none flex items-center" for="remember">
					<input id="remember" v-model="form.remember" class="mr-1" type="checkbox" />
					<span class="text-sm">Remember Me</span>
				</label>
			</div>
			<div class="px-10 py-4 bg-gray-100 border-t border-gray-100">
				<div class="mx-auto mt-6 w-24 border-b-2" />
				<div class="m-4 p-4">
					<div class="mx-auto"><loading-button :loading="form.processing" class="btn-indigo" type="submit">Login (For Demo Only)</loading-button></div>
					<div class="mx-auto py-4"><a class="btn-indigo" :href="route('login.graph')">Login with Microsoft</a></div>
				</div>
			</div>
		</form>
			<form v-else class="mt-8 bg-white rounded-lg shadow-xl overflow-hidden" @submit.prevent="login">
				<div class="px-10 py-12">
					<h1 class="text-center font-bold text-3xl">TB DIAH Results System</h1>
				</div>
				<div class="px-10 py-4 bg-gray-100 border-t border-gray-100">
					<div class="mx-auto py-4 text-center"><a class="btn-indigo" :href="route('login.graph')">Login with Microsoft</a></div>
				</div>
			</form>
		</div>
		<cookies-banner />
	</div>
</template>

<script>
	import Logo from '@/Shared/Logo'
	import TextInput from '@/Shared/TextInput'
	import LoadingButton from '@/Shared/LoadingButton'
	import CookiesBanner from '@/Shared/CookiesBanner'

	export default {
		metaInfo: { title: 'Login' },
		components: {
			LoadingButton,
			CookiesBanner,
			Logo,
			TextInput,
		},
		data() {
			return {
				form: this.$inertia.form({
					email: '',
					password: '',
					remember: false,
				}),
			}
		},
		methods: {
			login() {
				this.form.post(this.route('login.store'))
			},
		},
		computed: {
			isDebug() {
				return this.$page.props.appDebug
			}
		}
	}
</script>
