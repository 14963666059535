<template>
	<div>
		<portal-target name="dropdown" slim />
		<div class="md:flex md:flex-col">
		<div class="md:h-screen md:flex md:flex-col">
			<div class="md:flex md:flex-shrink-0">
			<div class="bg-indigo-900 md:flex-shrink-0 md:w-56 px-6 py-4 flex items-center justify-between md:justify-center">
				<inertia-link class="mt-1" :href="route('dashboard')">
				<logo width="120" height="28" />
				</inertia-link>
				<dropdown class="md:hidden" placement="bottom-end">
				<svg class="fill-white w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
				<div slot="dropdown" class="mt-2 px-8 py-4 shadow-lg bg-indigo-800 rounded">
					<main-menu />
				</div>
				</dropdown>
			</div>
			<div class="bg-white border-b w-full p-4 md:py-0 md:px-12 text-sm md:text-md flex justify-between items-center">
				<div class="mt-1 mr-4 flex-grow">{{ $page.props.auth.user.account.name }}</div>
				<div>
				<div
					class="text-gray-700 group-hover:text-indigo-600 focus:text-indigo-600 mt-2 px-4 pt-2 mr-1 whitespace-nowrap" 
					@click="getUnreadNotifications">
					<icon name="notifications-outline" class="w-3 h-3" />
					<transition name="bounce">
					<span v-if="$page.props.auth.user.notification.unread.count > 0" class="badge-no-text" />
					<span v-else class="badge-cleared" />
					</transition>
				</div>
				<div v-if="showNotifications" class="mt-0 px-0 py-0 shadow-xl bg-white rounded text-sm max-w-xs">
					<div style="position: fixed; top: 0; right: 0; left: 0; bottom: 0; z-index: 99998; background: black; opacity: .2" @click="showNotifications = false" />
					<div style="position: fixed; top: 65px; right: -5px; z-index: 99999;" @click.stop="showNotifications = autoClose ? false : true">
					<div class="mx-2 my-1 pb-0 border-b border-indigo-100 bg-white rounded-lg shadow-xl" 
						v-for="notification in this.notifications" 
						:key="notification.id" 
						v-bind:id="notification.id">
						<div class="block px-4 py-4 hover:text-indigo-500 cursor-default flex justify-between">
						<span class="px-2 py-2 mr-2 text-xs text-purple-500 bg-gray-100 rounded font-mono font-semibold self-center">{{ notification.data['date'] }}</span>
						<span class="text-indigo-400 self-center">{{ notification.data['description'] }}</span>
						<button type="btn" v-on:click="markRead(notification.id)">
							<icon name="close" class="w-4 h-4 ml-2 self-center" />
						</button>
						</div>
					</div>
					</div>
				</div>
				</div>
				<dropdown class="mt-1" placement="bottom-end">
				<div class="flex items-center cursor-pointer select-none group">                
					<div class="text-gray-700 group-hover:text-indigo-600 focus:text-indigo-600 mr-1 whitespace-nowrap">                  
					<span>{{ $page.props.auth.user.first_name }}</span>
					<span class="hidden md:inline">{{ $page.props.auth.user.last_name }}</span>
					</div>
					<div  v-if="$page.props.auth.user.photo" class="text-gray-700 group-hover:text-indigo-600 focus:text-indigo-600 mr-1 whitespace-nowrap">
					<img class="block w-8 h-8 rounded-full ml-4" :src="$page.props.auth.user.photo" />
					</div>
					<icon class="w-5 h-5 group-hover:fill-indigo-600 fill-gray-700 focus:fill-indigo-600" name="cheveron-down" />
				</div>
				<div slot="dropdown" class="mt-2 py-2 shadow-xl bg-white rounded text-sm">
					<inertia-link v-if="$page.props.auth.user.perm.settings.edit" class="block px-6 py-2 hover:bg-indigo-500 hover:text-white" :href="route('users.edit', $page.props.auth.user.id)">My Profile</inertia-link>
					<inertia-link v-if="$page.props.auth.user.perm.settings.edit" class="block px-6 py-2 hover:bg-indigo-500 hover:text-white" :href="route('users')">Manage Users</inertia-link>
					<inertia-link v-if="$page.props.auth.user.id != $page.props.auth.user.perm.userID" class="block px-6 py-2 hover:bg-indigo-500 hover:text-white" :href="route('users.resetuser')">Revert To Your True Self</inertia-link>
					<a class="block px-6 py-2 hover:bg-indigo-500 hover:text-white w-full text-left" :href="route('logout.graph')">Logout</a>
				</div>
				</dropdown>
			</div>
			</div>
			<div class="md:flex md:flex-grow md:overflow-hidden">
			<main-menu class="hidden md:block bg-indigo-800 flex-shrink-0 w-56 p-6 overflow-y-auto" />
			<div class="md:flex-1 px-4 py-8 md:p-12 md:overflow-y-auto" scroll-region>
				<flash-messages />
				<slot />
			</div>
			</div>
		</div>
		</div>
		<cookies-banner />
	</div>
</template>

<script>
import Icon from '@/Shared/Icon'
import Logo from '@/Shared/Logo'
import Dropdown from '@/Shared/Dropdown'
import MainMenu from '@/Shared/MainMenu'
import FlashMessages from '@/Shared/FlashMessages'
import CookiesBanner from '@/Shared/CookiesBanner'

import axios from 'axios'

export default {
  components: {
    Dropdown,
    FlashMessages,
	CookiesBanner,
    Icon,
    Logo,
    MainMenu,
  },
  props: {
    notifications: null,
    showNotifications: false,
    autoClose: true,
  },
  data() {
    return {
    }
  },
  methods: {
    getUnreadNotifications() {

      axios.get(this.$page.props.auth.user.url + '/getunreadnotifications/')
        .then(response => {
          this.notifications = response.data.notifications;
          this.$page.props.auth.user.notification.unread.count = response.data.notifications.length;
          this.showNotifications = true;
        })
        .catch(errors => {
          console.log(errors);
        });
    },
    markRead(id) {

      axios.post(this.$page.props.auth.user.url + '/markread', { "id": id })
        .then(response => {
          this.notifications = response.data.notifications;
          this.$page.props.auth.user.notification.unread.count = response.data.notifications.length;
        })
        .catch(errors => {
          console.log(errors);
        });
    },
  },
}
</script>
