<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('settings.show', 1)">Settings</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      System Settings
    </h1>
    <trashed-message v-if="setting.deleted_at" class="mb-6" @restore="restore">
      This setting has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Years:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.years }}</p>
      </div>
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Note Types:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.note_types }}</p>
      </div>
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Authentication Domains:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.auth_domains }}</p>
      </div>
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">MIS URL:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ setting.mis_url }}</p>
      </div>
<!-- 
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Intended geographic use:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.geographic }}</p>
      </div>
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Year Requested:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.year_requested }}</p>
      </div>
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Product level:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.product_level }}</p>
      </div>
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Type of electronic media recipient:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.media }}</p>
      </div>
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Type of decision made:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.decision_made }}</p>
      </div>
      <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Type of system improvement:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 whitespace-pre">{{ setting.system_improvement }}</p>
      </div> -->

      <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
        <span class="text-indigo-600">Laravel v{{ this.laravel_version }} / {{ this.git_version }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {
    }
  },
  components: {
    TrashedMessage,
  },
  layout: Layout,
  props: {
    setting: Object,
    laravel_version: String,
    git_version: String,
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
