<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">Field Option Control</h1>
    <div class="mb-6 flex justify-between items-center">
      <search-filter v-model="form.search" class="w-full max-w-md mr-4" @reset="reset">        
        <label class="mt-4 block text-gray-700">Trashed:</label>
        <select v-model="form.trashed" class="mt-1 w-full form-select">
          <option :value="null" />
          <option value="with">With Trashed</option>
          <option value="only">Only Trashed</option>
        </select>
      </search-filter>      
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="px-6 pt-6 pb-4">Field Names</th>
          <th class="px-6 pt-6 pb-4">Indicators</th>
        </tr>
        <tr v-for="optfield in optfields" :key="optfield.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t align-top">
            <inertia-link class="px-6 py-4 flex items-center focus:text-indigo-500" :href="route('optfield.edit', optfield.id)">              
              {{ optfield.name }}
            </inertia-link>
          </td>
          <td class="border-t">
            <inertia-link class="px-6 py-4 flex items-center" :href="route('optfield.edit', optfield.id)" tabindex="-1">
              <div v-if="optfield.indicators"> 
                  <template v-for="(element, index) in optfield.indicators">
                    <div :key="index">{{element.short_version}}</div>
                  </template>
                    
              </div>
            </inertia-link>
          </td>
          <td class="border-t w-px">
            <inertia-link class="px-4 flex items-center" :href="route('optfield.edit', optfield.id)" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </inertia-link>
          </td>
        </tr>
        <tr v-if="optfields.length === 0">
          <td class="border-t px-6 py-4" colspan="4">No field found.</td>
        </tr>
      </table>
    </div>    
  </div>
</template>

<script>
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  metaInfo: { title: 'Users' },
  components: {  
    SearchFilter,
    Icon,
  },
  layout: Layout,
  props: {
    filters: Object,
    optfields: Array,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(this.route('optfield.index'), pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)

      // Reset sorting
    }
  },
}
</script>
