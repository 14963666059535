<template>
    <div>
        <div class="mb-4 grid grid-flow-col grid-cols-2 gap-2">
            <file-input v-model="section.value" type="file" />
            <div>
                <button type="button" class="btn-red ml-auto text-sm" v-on:click="$emit('remove')">
                    <icon name="close" class="w-4 h-4 fill-white" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import FileInput from '@/Shared/FileInput'
import Icon from '@/Shared/Icon'

export default {
    props: ['section'],
    components: {
        FileInput,
        Icon,
    },
    data: function() {
        return {
        }
    },
};
</script>