<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('settings.show', 1)">Settings</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      System Settings
    </h1>
    <trashed-message v-if="setting.deleted_at" class="mb-6" @restore="restore">
      This setting has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.years" :error="form.errors.years" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Years" />
        </div>
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.note_types" :error="form.errors.note_types" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Note Types" />
        </div>
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.auth_domains" :error="form.errors.auth_domains" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Authentication Domains" />
        </div>
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <text-input v-model="form.mis_url" :error="form.errors.mis_url" class="pr-6 pb-8 w-full lg:w-3/4 font-medium" label="MIS URL" />
        </div>
        
        <!-- <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.geographic" :error="form.errors.geographic" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Intended geographic use" />
        </div>        
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.year_requested" :error="form.errors.year_requested" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Year Requested" />
        </div>
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.product_level" :error="form.errors.product_level" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Product level" />
        </div>
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.media" :error="form.errors.media" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Type of electronic media recipient" />
        </div>
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.decision_made" :error="form.errors.decision_made" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Type of decision made" />
        </div>
        <div class="px-8 pt-8 pb-2 -mr-6 -mb-8 flex flex-wrap">
          <TextareaInput v-model="form.system_improvement" :error="form.errors.system_improvement" class="pr-6 pb-8 w-full lg:w-3/4 min-h-40 font-medium" label="Type of system improvement" />
        </div> -->

        <div class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
          <!-- button v-if="!setting.deleted_at" class="text-red-600 hover:underline" tabindex="-1" type="button" @click="destroy">Delete Setting</button -->
          <span class="text-indigo-600">PHP {{ this.php_version }} / Laravel {{ this.laravel_version }} / {{ this.git_version }}</span>
          <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update Setting</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import TextareaInput from '@/Shared/TextareaInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  metaInfo() {
    return {
    }
  },
  components: {
    LoadingButton,
    SelectInput,
    TextInput,
    TextareaInput,
    TrashedMessage,
  },
  layout: Layout,
  props: {
    setting: Object,
    laravel_version: String,
    git_version: String,
    php_version: String,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        years: this.setting.years,
        note_types: this.setting.note_types,
        auth_domains: this.setting.auth_domains,
        mis_url: this.setting.mis_url,
        
        geographic: this.setting.geographic,
        year_requested: this.setting.year_requested,
        product_level: this.setting.product_level,
        media: this.setting.media,
        decision_made: this.setting.decision_made,
        system_improvement: this.setting.system_improvement,
      }),
    }
  },
  methods: {
    update() {
      this.form.put(this.route('settings.update', this.setting.id))
    },
    destroy() {
      if (confirm('Are you sure you want to delete this setting?')) {
        this.$inertia.delete(this.route('settings.destroy', this.setting.id))
      }
    },
    restore() {
      if (confirm('Are you sure you want to restore this setting?')) {
        this.$inertia.put(this.route('settings.restore', this.setting.id))
      }
    },
  },
}
</script>
