<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('results.index')">Results</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ result.title }}
    </h1>
    <trashed-message v-if="result.deleted_at" class="mb-6" @restore="restore">
      This result has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Title:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.title }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Narrative:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.narrative }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Status:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 text-indigo-400">{{ this.status }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Indicator:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-if="this.indicator">{{ this.indicator.short_version }}</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>

        <div class="mb-8 mr-8 p-4 pt-8 w-full flex flex-wrap shadow-lg bg-gray-50" v-show="boolA1 || boolA2 || boolA3 || boolA4 || boolA5 || boolA6 || boolA7 || boolA8 || boolB1 || boolB2 || boolB4 || boolB6 || boolC1 || boolC2 || boolT1">
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA1 || boolB1">Type of TB Data Hub resource or information product :</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA1 || boolB1"><span v-if="this.hubresource">{{ this.hubresource.short_version }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA1 || boolA6 || boolB1">Intended geographic use:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA1 || boolA6 || boolB1">{{ result.geographic }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA2 || boolB2">Type of Assessment:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA2 || boolB2"><span v-if="this.typeassessment">{{ this.typeassessment.short_version }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA3">Does work plan include training:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA3">{{ result.include_training ? 'Yes' : 'No' }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA4 || boolB2 || boolB4 || boolB6">Type of stakeholder:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA4 || boolB2 || boolB4 || boolB6">
            <ul v-if="this.typestakeholder_ids.length">
              <li class="pb-2" v-for="typestakeholder in this.typestakeholder_ids" :key="typestakeholder.id">
                {{ typestakeholder.short_version }}
              </li>
            </ul>
            <template v-else>&nbsp;</template>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA5">Aspect of PBMEF Reporting:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA5">
            <ul v-if="this.pbmef_ids.length">
              <li class="pb-2" v-for="pbmef in this.pbmef_ids" :key="pbmef.id">
                {{ pbmef.short_version }}
              </li>
            </ul>
            <template v-else>&nbsp;</template>
          </p>        
          
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA6 || boolB6">Product type:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA6 || boolB6">
            <ul v-if="this.producttype_ids.length">
              <li class="pb-2" v-for="producttype in this.producttype_ids" :key="producttype.id">
                {{ producttype.short_version }}
              </li>
            </ul>
            <template v-else>&nbsp;</template>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA1 ||boolA6 || boolB2 || boolB4 || boolB6">Type of skill supported:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA1 ||boolA6 || boolB2 || boolB4 || boolB6">
            <ul v-if="this.skillsupported_ids.length">
              <li class="pb-2" v-for="skillsupported in this.skillsupported_ids" :key="skillsupported.id">
                {{ skillsupported.short_version }}
              </li>
            </ul>
            <template v-else>&nbsp;</template>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA1 ||boolA2 ||boolA6 || boolB1 || boolC1">Product level:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA1 ||boolA2 ||boolA6 || boolB1 || boolC1"><span v-if="result.product_level.length">{{ result.product_level.join(', ') }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA7">Type of dissemination event:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA7">
            <ul v-if="this.dissemination_ids.length">
              <li class="pb-2" v-for="dissemination in this.dissemination_ids" :key="dissemination.id">
                {{ dissemination.short_version }}
              </li>
            </ul>
            <template v-else>&nbsp;</template>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA8">Type of electronic media recipient:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA8">
            <span v-if="this.media_ids.length">
              <div class="pb-2 flex flex-wrap" v-for="media in this.media_ids" :key="media.id">
                <div class="w-full md:w-1/2"> {{ media.media_id.short_version }} </div><div class="w-1/2">Qty: {{ media.qty }}</div>
              </div>
            </span>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA5 || boolA8">Year Requested:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA5 || boolA8"><span v-if="result.year_requested">{{ result.year_requested }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolB1">TB Roadmap Technical Area:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolB1">
            <ul v-if="this.roadmap_ids.length">
              <li class="pb-2" v-for="roadmap in this.roadmap_ids" :key="roadmap.id">
                {{ roadmap.short_version }}
              </li>
            </ul>
            <template v-else>&nbsp;</template>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolC1">Type of decision-maker:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolC1">
            <ul v-if="this.decisionmaker_ids.length">
              <li class="pb-2" v-for="decisionmaker in this.decisionmaker_ids" :key="decisionmaker.id">
                {{ decisionmaker.short_version }}
              </li>
            </ul>
            <template v-else>&nbsp;</template>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolC1">Type of decision made:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolC1"><span v-if="result.decision_made.length">{{ result.decision_made.join(', ') }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolC2">Type of system improvement:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolC2"><span v-if="result.system_improvement.length">{{ result.system_improvement.join(', ') }}</span></p>

          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolT1">No. of Females Trained:</p>
          <p class="pr-6 pb-4 w-full lg:w-3/4" v-show="boolT1">{{ result.no_of_females_trained }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolT1">No. of Males Trained:</p>
          <p class="pr-6 pb-4 w-full lg:w-3/4" v-show="boolT1">{{ result.no_of_males_trained }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolT1">No. of Unknown Trained:</p>
          <p class="pr-6 pb-4 w-full lg:w-3/4" v-show="boolT1">{{ result.no_of_unknown_gender_trained }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolT1">Total Number Trained:</p>
          <p class="pr-6 pb-2 w-full lg:w-3/4" v-show="boolT1">{{ result.total_no_trained }}</p>
        </div>

        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Result Areas:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside"  v-if="this.resultarea_ids.length">
          <li class="pb-4" v-for="resultarea in this.resultarea_ids" :key="resultarea.id">
            {{ resultarea.short_version }}
          </li>
        </ul>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Country(ies):</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside"  v-if="this.country_ids.length">
          <li class="pb-4" v-for="country in this.country_ids" :key="country.country_id">
            {{ country.long_name }}
          </li>
        </ul>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Primary MEL Contact:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-if="this.pmcontact">{{ this.pmcontact.first_name }} {{ this.pmcontact.last_name }}</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-else> n/a </p>
        <!-- <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Gender:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.gender ? 'Yes' : 'No' }}</p> -->
        
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Year Result Met:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><span v-if="result.year_result_met.length">{{ result.year_result_met.join(', ') }}</span></p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Year Result Reported:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><span v-if="result.year_result_reported.length">{{ result.year_result_reported.join(', ') }}</span></p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Files:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><DataGridReadOnlyFileField :sections="fileuploads" :is_note_file="false"></DataGridReadOnlyFileField></p>
        <p v-if="$page.props.auth.user.perm.records.non_usaid_view" class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Notes:</p>
        <p v-if="$page.props.auth.user.perm.records.non_usaid_view" class="pr-6 pb-8 w-full lg:w-3/4"><DataGridReadOnlyNoteFileField v-if="notes.length > 0" :sections="notes" class="pb-8 mt-4"></DataGridReadOnlyNoteFileField></p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Associated Activities:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="activity in this.activity_ids" :key="activity.activity_id">
            <a class="text-indigo-400" :href="`${ activity_url_prefix }${ activity.activity_code }`" target='_blank'>{{ activity.activity_code }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Contributing Sub-Activities:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="subactivity in this.subactivity_ids" :key="subactivity.uuid">
            <a class="text-indigo-400" :href="`${ subactivity_url_prefix }${ subactivity.uuid }`" target='_blank'>{{ subactivity.title }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:w-1/4 lg:text-right font-medium">Contributing Deliverables:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="deliverable in this.deliverable_ids" :key="deliverable.uuid">
            <a class="text-indigo-400" :href="`${ deliverable_url_prefix }${ deliverable.uuid }`" target='_blank'>{{ deliverable.title }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Contributing Stakeholders:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="activitystakeholder in this.activitystakeholder_ids" :key="activitystakeholder.uuid">
            <!-- <a class="text-indigo-400" :href="`${ activitystakeholder_url_prefix }${ activitystakeholder.id }`" target='_blank'>{{ activitystakeholder.long_name }}</a> -->
            {{ activitystakeholder.long_name }}
          </li>
        </ul>

        

      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TrashedMessage from '@/Shared/TrashedMessage'
import DataGridReadOnlyFileField from '@/Shared/DataGridReadOnlyFileField'
import DataGridReadOnlyNoteFileField from '@/Shared/DataGridReadOnlyNoteFileField'

export default {
  metaInfo() {
    return {
    }
  },
  components: {
    TrashedMessage,
    DataGridReadOnlyFileField,
    DataGridReadOnlyNoteFileField,
  },
  layout: Layout,
  computed: {
    boolA1: function () { if (this.indicator) { return this.indicator.id.startsWith('60886d68-d855-400a-85b0-0a4404920c72') } else { return false } }, // A1
    boolA2: function () { if (this.indicator) { return this.indicator.id.startsWith('63274f2e-7612-4383-a05c-f7468b7321e2') } else { return false } }, // A2
    boolA3: function () { if (this.indicator) { return this.indicator.id.startsWith('e3c3be82-857a-4b53-9bda-23086765a491') } else { return false } }, // A3
    boolA4: function () { if (this.indicator) { return this.indicator.id.startsWith('8e98e53b-5d87-4358-a3b9-c75bc38c1b32') } else { return false } }, // A4
    boolA5: function () { if (this.indicator) { return this.indicator.id.startsWith('2fc99d38-a5b6-4b21-83c4-0821f0b635b1') } else { return false } }, // A5
    boolA6: function () { if (this.indicator) { 
      if(this.indicator.id.startsWith('5daad65b-0e2b-4b3f-90d9-77c165cb5aa8') || // A6
        this.indicator.id.startsWith('39d31ba7-8363-4129-99d4-9e62be666d11') || // A7
        this.indicator.id.startsWith('7850e492-ec6e-4071-af89-aac8e090cf5e')) {  // A8
          return true 
      }
    } else { return false } }, // A6
    boolA7: function () { if (this.indicator) { return this.indicator.id.startsWith('0bd0d3fb-34ef-4c45-a7f4-73aa4f567e82') } else { return false } }, // A7 -> A9
    boolA8: function () { if (this.indicator) { return this.indicator.id.startsWith('07af3b6b-6435-4391-9474-ad5d40a2f062') } else { return false } }, // A8 ->A10
    boolB1: function () { if (this.indicator) { return this.indicator.id.startsWith('aee54731-ef98-468e-a001-9e559eed8bdd') } else { return false } }, // B1
    boolB2: function () { if (this.indicator) { return this.indicator.id.startsWith('da3c9bef-be4e-48dc-8212-6e4e8def69bf') } else { return false } }, // B2
    boolB4: function () { if (this.indicator) { return this.indicator.id.startsWith('243b4fa7-6cc6-4f40-8317-8710054c7c8d') } else { return false } }, // B4
    boolB6: function () { if (this.indicator) { 
      if(this.indicator.id.startsWith('e2fce0a9-b51f-4297-8481-3238278cf55f') || // B6
          this.indicator.id.startsWith('31d227b2-80ac-497a-b1d9-79bf67f0d7b5') || // B7
          this.indicator.id.startsWith('eff7fc75-3007-4e2e-bb74-5b5193bd0ebd')) { // B8
        return true
      }
    } else { return false } }, // B6
    boolC1: function () { if (this.indicator) { return this.indicator.id.startsWith('548cbb70-7436-438e-9aa0-abfd6deeb949') } else { return false } }, // C1
    boolC2: function () { if (this.indicator) { return this.indicator.id.startsWith('3ca93ac8-d43c-4c5f-817e-8185f4d5d7d7') } else { return false } }, // C2
    boolT1: function () { if (this.indicator) { return this.indicator.id.startsWith('dee79026-3d9e-4384-9b39-eb6a9b24fc25') } else { return false } }, // T1 -> A12
  },
  props: {
    result: Object,
    indicator: Object,
    resultarea_ids: Array,
    country_ids: Array,
    pmcontact: Object,
    fileuploads: Array,
    notes: Array,
    subactivity_ids: Array,
    deliverable_ids: Array,
    media_ids: Array,
    activitystakeholder_ids: Array,
    needsuse_ids: Array,
    status: String,
    activity_ids: Array,
    activity_url_prefix: String,
    subactivity_url_prefix: String,
    deliverable_url_prefix: String,
    activitystakeholder_url_prefix: String,
    needsuse_url_prefix: String,
    hubresource: Object,
    typeassessment: Object,
    resultarea_ids: Array,
    pbmef_ids: Array,
    typestakeholder_ids: Array,
    producttype_ids: Array,
    roadmap_ids: Array,
    skillsupported_ids: Array,
    decisionmaker_ids: Array,
    dissemination_ids: Array,
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
