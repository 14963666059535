<template>
  <div>
    <h1 class="mb-8 font-bold text-3xl">
      <inertia-link class="text-indigo-400 hover:text-indigo-600" :href="route('results.index')">Results</inertia-link>
      <span class="text-indigo-400 font-medium">/</span>
      {{ result.title }}
    </h1>
    <trashed-message v-if="result.deleted_at" class="mb-6" @restore="restore">
      This result has been deleted.
    </trashed-message>
    <div class="bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="update">
      <div class="p-8 -mr-6 -mb-8 flex flex-wrap">
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Title:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.title }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Narrative:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.narrative }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Status:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 text-indigo-400">{{ this.status }}</p>
        <p class="pr-6 pb-4 w-full lg:w-1/4 lg:text-right font-medium">Indicator:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-if="this.indicator">{{ this.indicator.short_version }}</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>

        <div class="mb-8 mr-8 p-4 pt-8 w-full flex flex-wrap shadow-lg bg-gray-50" v-show="boolA1 || boolA2 || boolA3 || boolA4 || boolA5 || boolA6 || boolA7 || boolA8 || boolB1 || boolB2 || boolB4 || boolB6 || boolC1 || boolC2 || boolT1">
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA1 || boolB1">Type of TB Data Hub resource or information product :</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA1 || boolB1"><span v-if="this.hubresource">{{ this.hubresource.short_version }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA1 || boolA6 || boolB1">Intended geographic use:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA1 || boolA6 || boolB1">{{ result.geographic }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA2 || boolB2">Type of Assessment:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA2 || boolB2"><span v-if="this.typeassessment">{{ this.typeassessment.short_version }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA3">Does work plan include training:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA3">{{ result.include_training ? 'Yes' : 'No' }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA4 || boolB2 || boolB4 || boolB6">Type of stakeholder:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA4 || boolB2 || boolB4 || boolB6">
            <ul v-if="this.typestakeholder_ids.length">
              <li class="pb-2" v-for="typestakeholder in this.typestakeholder_ids" :key="typestakeholder.id">
                {{ typestakeholder.short_version }}
              </li>
            </ul>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA5">Aspect of PBMEF Reporting:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA5">
            <ul v-if="this.pbmef_ids.length">
              <li class="pb-2" v-for="pbmef in this.pbmef_ids" :key="pbmef.id">
                {{ pbmef.short_version }}
              </li>
            </ul>
          </p>        
          
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA6 || boolB6">Product type:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA6 || boolB6">
            <ul v-if="this.producttype_ids.length">
              <li class="pb-2" v-for="producttype in this.producttype_ids" :key="producttype.id">
                {{ producttype.short_version }}
              </li>
            </ul>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA1 ||boolA6 || boolB2 || boolB4 || boolB6">Type of skill supported:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA1 ||boolA6 || boolB2 || boolB4 || boolB6">
            <ul v-if="this.skillsupported_ids.length">
              <li class="pb-2" v-for="skillsupported in this.skillsupported_ids" :key="skillsupported.id">
                {{ skillsupported.short_version }}
              </li>
            </ul>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA1 ||boolA2 ||boolA6 || boolB1 || boolC1">Product level:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA1 ||boolA2 ||boolA6 || boolB1 || boolC1"><span v-if="result.product_level.length">{{ result.product_level.join(', ') }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA7">Type of dissemination event:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA7">
            <ul v-if="this.dissemination_ids.length">
              <li class="pb-2" v-for="dissemination in this.dissemination_ids" :key="dissemination.id">
                {{ dissemination.short_version }}
              </li>
            </ul>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA8">Type of electronic media recipient:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA8">
            <span v-if="this.media_ids.length">
              <div class="pb-2 flex flex-wrap" v-for="media in this.media_ids" :key="media.id">
                <div class="w-full md:w-1/2"> {{ media.media_id.short_version }} </div><div class="w-1/2">Qty: {{ media.qty }}</div>
              </div>
            </span>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolA5 || boolA8">Year Requested:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolA5 || boolA8"><span v-if="result.year_requested">{{ result.year_requested }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolB1">TB Roadmap Technical Area:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolB1">
            <ul v-if="this.roadmap_ids.length">
              <li class="pb-2" v-for="roadmap in this.roadmap_ids" :key="roadmap.id">
                {{ roadmap.short_version }}
              </li>
            </ul>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolC1">Type of decision-maker:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolC1">
            <ul v-if="this.decisionmaker_ids.length">
              <li class="pb-2" v-for="decisionmaker in this.decisionmaker_ids" :key="decisionmaker.id">
                {{ decisionmaker.short_version }}
              </li>
            </ul>
          </p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolC1">Type of decision made:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolC1"><span v-if="result.decision_made.length">{{ result.decision_made.join(', ') }}</span></p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolC2">Type of system improvement:</p>
          <p class="pr-6 pb-6 w-full lg:w-3/4" v-show="boolC2"><span v-if="result.system_improvement.length">{{ result.system_improvement.join(', ') }}</span></p>

          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolT1">No. of Females Trained:</p>
          <p class="pr-6 pb-4 w-full lg:w-3/4" v-show="boolT1">{{ result.no_of_females_trained }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolT1">No. of Males Trained:</p>
          <p class="pr-6 pb-4 w-full lg:w-3/4" v-show="boolT1">{{ result.no_of_males_trained }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolT1">No. of Unknown Trained:</p>
          <p class="pr-6 pb-4 w-full lg:w-3/4" v-show="boolT1">{{ result.no_of_unknown_gender_trained }}</p>
          <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium" v-show="boolT1">Total Number Trained:</p>
          <p class="pr-6 pb-2 w-full lg:w-3/4" v-show="boolT1">{{ result.total_no_trained }}</p>
        </div>

        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Result Areas:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside"  v-if="this.resultarea_ids.length">
          <li class="pb-4" v-for="resultarea in this.resultarea_ids" :key="resultarea.id">
            {{ resultarea.short_version }}
          </li>
        </ul>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Country(ies):</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside"  v-if="this.country_ids.length">
          <li class="pb-4" v-for="country in this.country_ids" :key="country.country_id">
            {{ country.long_name }}
          </li>
        </ul>
        <p class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside" v-else>&nbsp;</p>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Primary MEL Contact:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-if="this.pmcontact">{{ this.pmcontact.first_name }} {{ this.pmcontact.last_name }}</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4" v-else> n/a </p>
        <!-- <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Gender:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4">{{ result.gender ? 'Yes' : 'No' }}</p> -->
        
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Year Result Met:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><span v-if="result.year_result_met.length">{{ result.year_result_met.join(', ') }}</span></p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Year Result Reported:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><span v-if="result.year_result_reported.length">{{ result.year_result_reported.join(', ') }}</span></p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Files:</p>
        <p class="pr-6 pb-8 w-full lg:w-3/4"><DataGridReadOnlyFileField :sections="fileuploads" :is_note_file="false"></DataGridReadOnlyFileField></p>
        <p class="pr-6 pb-8 w-full lg:w-1/4 lg:text-right font-medium">Notes:</p>
        
        <p v-if="notesStaff.length > 0" class="pr-6 pb-8 w-full lg:w-3/4">
          <DataGridReadOnlyNoteFileField :sections="notesStaff"></DataGridReadOnlyNoteFileField>
        </p>
        <p v-if="notesStaff.length > 0" class="pr-6 w-full lg:w-1/4 lg:text-right font-medium">&nbsp;</p>

              <DataGridNoteFileField v-if="!result.deleted_at"
                :sections="form.notes" 
                :note_types="note_types"
                :deleted_notes="form.deleted_notes" 
                class="pr-6 pb-8 w-full lg:w-3/4"></DataGridNoteFileField>
          

                <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Associated Activities:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="activity in this.activity_ids" :key="activity.activity_id">
            <a class="text-indigo-400" :href="`${ activity_url_prefix }${ activity.activity_code }`" target='_blank'>{{ activity.activity_code }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Contributing Sub-Activities:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="subactivity in this.subactivity_ids" :key="subactivity.uuid">
            <a class="text-indigo-400" :href="`${ subactivity_url_prefix }${ subactivity.uuid }`" target='_blank'>{{ subactivity.title }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:w-1/4 lg:text-right font-medium">Contributing Deliverables:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="deliverable in this.deliverable_ids" :key="deliverable.uuid">
            <a class="text-indigo-400" :href="`${ deliverable_url_prefix }${ deliverable.uuid }`" target='_blank'>{{ deliverable.title }}</a>
          </li>
        </ul>
        <p class="pr-6 pb-2 w-full lg:w-1/4 lg:text-right font-medium">Contributing Stakeholders:</p>
        <ul class="pr-6 pb-8 w-full lg:w-3/4 list-disc list-inside">
          <li class="pb-4" v-for="activitystakeholder in this.activitystakeholder_ids" :key="activitystakeholder.uuid">
            <!-- <a class="text-indigo-400" :href="`${ activitystakeholder_url_prefix }${ activitystakeholder.id }`" target='_blank'>{{ activitystakeholder.long_name }}</a> -->
            {{ activitystakeholder.long_name }}
          </li>
        </ul>
      </div>
      <div v-if="!result.deleted_at" class="px-8 py-4 bg-gray-50 border-t border-gray-100 flex items-center">
        <loading-button :loading="form.processing" class="btn-indigo ml-auto" type="submit">Update Result</loading-button>
      </div>
      </form>
    </div>
  </div>
</template>

<script>
import Layout from '@/Shared/Layout'
import TrashedMessage from '@/Shared/TrashedMessage'
import DataGridReadOnlyFileField from '@/Shared/DataGridReadOnlyFileField'
import DataGridReadOnlyNoteFileField from '@/Shared/DataGridReadOnlyNoteFileField'
import LoadingButton from '@/Shared/LoadingButton'
import DataGridNoteFileField from '@/Shared/DataGridNoteFileField'

export default {
  metaInfo() {
    return {
    }
  },
  components: {
    LoadingButton,
    TrashedMessage,
    DataGridReadOnlyFileField,
    DataGridReadOnlyNoteFileField,
    DataGridNoteFileField,
  },
  layout: Layout,
  computed: {
    boolA1: function () { if (this.indicator) { return this.indicator.short_version.startsWith('A1') } else { return false } },
    boolA2: function () { if (this.indicator) { return this.indicator.short_version.startsWith('A2') } else { return false } },
    boolA3: function () { if (this.indicator) { return this.indicator.short_version.startsWith('A3') } else { return false } },
    boolA4: function () { if (this.indicator) { return this.indicator.short_version.startsWith('A4') } else { return false } },
    boolA5: function () { if (this.indicator) { return this.indicator.short_version.startsWith('A5') } else { return false } },
    boolA6: function () { if (this.indicator) { return this.indicator.short_version.startsWith('A6') } else { return false } },
    boolA7: function () { if (this.indicator) { return this.indicator.short_version.startsWith('A7') } else { return false } },
    boolA8: function () { if (this.indicator) { return this.indicator.short_version.startsWith('A8') } else { return false } },
    boolB1: function () { if (this.indicator) { return this.indicator.short_version.startsWith('B1') } else { return false } },
    boolB2: function () { if (this.indicator) { return this.indicator.short_version.startsWith('B2') } else { return false } },
    boolB4: function () { if (this.indicator) { return this.indicator.short_version.startsWith('B4') } else { return false } },
    boolB6: function () { if (this.indicator) { return this.indicator.short_version.startsWith('B6') } else { return false } },
    boolC1: function () { if (this.indicator) { return this.indicator.short_version.startsWith('C1') } else { return false } },
    boolC2: function () { if (this.indicator) { return this.indicator.short_version.startsWith('C2') } else { return false } },
    boolT1: function () { if (this.indicator) { return this.indicator.short_version.startsWith('T1') } else { return false } },
    showTrainingFields: function () {
      if (this.indicator)
        return this.indicator.short_version.startsWith('A6')
      else
        return false
    }
  },
  props: {
    result: Object,
    indicator: Object,
    resultarea_ids: Array,
    country_ids: Array,
    pmcontact: Object,
    fileuploads: Array,
    notes: Array,
    subactivity_ids: Array,
    deliverable_ids: Array,
    media_ids: Array,
    activitystakeholder_ids: Array,
    needsuse_ids: Array,
    status: String,
    activity_ids: Array,
    activity_url_prefix: String,
    subactivity_url_prefix: String,
    deliverable_url_prefix: String,
    activitystakeholder_url_prefix: String,
    needsuse_url_prefix: String,
    hubresource: Object,
    typeassessment: Object,
    resultarea_ids: Array,
    pbmef_ids: Array,
    typestakeholder_ids: Array,
    producttype_ids: Array,
    roadmap_ids: Array,
    skillsupported_ids: Array,
    decisionmaker_ids: Array,
    dissemination_ids: Array,
    note_types: Array,
    notes: Array,
    notesStaff: Array,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        _method: 'put',
        notes: this.notes,
        deleted_notes: [],
      }),
    }
  },
  methods: {
    update() {
      this.form.post(this.route('results.activityput', this.result.id), {
        onSuccess: () => { 
          this.form.deleted_uploads = [];
          this.form.notes = this.$page.props.notes; 
        }
      })
    },
  },
}
</script>
